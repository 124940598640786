import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { useGraphDeleteMutation } from '@apolloGenerated';

export type GraphRemoveModalProps = {
  uuid: string;
  name: string;
  onSuccess?(): void;
};
declare global {
  interface ModalProps {
    removeGraph: GraphRemoveModalProps;
  }
}
export const GraphRemoveModal: FC<WithCloseModal<GraphRemoveModalProps>> = ({
  name,
  uuid,
  onSuccess,
  onClose,
}) => {
  const [deleteMutation, { loading }] = useGraphDeleteMutation();
  const { t } = useTranslation();
  const handleSubmit = async () => {
    await deleteMutation({ variables: { uuid } });
    onSuccess && onSuccess();
    onClose();
  };

  return (
    <Dialog
      width={500}
      title={t('titles.confirmDelete')}
      footerSlot={
        <DialogFooter
          position="end"
          startSlot={
            <Button size="large" color="secondary" fullWidth onClick={onClose}>
              {t('titles.cancel')}
            </Button>
          }
          endSlot={
            <Button onClick={handleSubmit} size="large" disabled={loading} fullWidth>
              {t('buttons.delete')}
            </Button>
          }
        />
      }
    >
      <Typography className="break-word" variant="body-16" color="on-surface-primary-1">
        {t('strings.deleteGraph')}: “{name}”?
        <br />
        {t('strings.deletedGraphAlert')}
      </Typography>
    </Dialog>
  );
};
