import React, { FC } from 'react';
import { IconProps } from '@rubin-dev/goblin';

export const PinFilledIcon: FC<IconProps> = ({ width = 20, height = 21, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect y="0.630859" width="20" height="20" rx="10" fill="#A3A9BD" />
      <path
        d="M9.82402 8.13317L12.53 5.4272L15.236 8.13317L12.53 10.8391C12.8119 11.4593 13.0035 13.0716 11.5152 14.5598L8.80928 11.8539L6.10331 9.14791C7.5916 7.65963 9.2039 7.8513 9.82402 8.13317Z"
        fill="white"
      />
      <path
        d="M12.53 5.4272L9.82402 8.13317C9.2039 7.8513 7.5916 7.65963 6.10331 9.14791L8.80928 11.8539M12.53 5.4272L11.8535 4.75071M12.53 5.4272L15.236 8.13317M15.236 8.13317L12.53 10.8391C12.8119 11.4593 13.0035 13.0716 11.5152 14.5598L8.80928 11.8539M15.236 8.13317L15.9124 8.80966M8.80928 11.8539L4.76521 15.8979"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
