import { CSSProperties, FC, useRef } from 'react';
import { AddressTransactionsFilterTxType } from '@apolloGenerated';
import { useIsEllipsis } from '@hooks/useIsEllipsis';
import { Tooltip, Typography, TypographyVariantType } from '@rubin-dev/goblin';
import { amount as formatAmount } from '@aml/amount';

export interface TableCellAmountProps {
  amount: string | number;
  network: string;
  type?: AddressTransactionsFilterTxType;
  showSymbol?: boolean;
  style?: CSSProperties;
  variant?: TypographyVariantType;
  currency?: boolean;
}

export const TableCellAmount: FC<TableCellAmountProps> = ({
  amount,
  network,
  type,
  showSymbol,
  style,
  variant = 'body-14',
  currency,
}): JSX.Element => {
  const textRef = useRef<HTMLElement>(null);
  const isEllipsis = useIsEllipsis(textRef, amount);
  const isSentTr = type === AddressTransactionsFilterTxType.Sent;
  const isReceiveTr = type === AddressTransactionsFilterTxType.Receives;
  const color = isSentTr ? 'error-2' : isReceiveTr ? 'success-2' : 'on-surface-primary-1';
  const amountValue = `${isSentTr ? '-' : isReceiveTr ? '+' : ''}${formatAmount(
    amount || '0',
    network,
    !!showSymbol,
  )}`;

  return (
    <Tooltip
      placement="top"
      disabled={!amount || !isEllipsis}
      label={
        <Typography variant={variant} color={color}>
          {amountValue}
        </Typography>
      }
    >
      <Typography
        variant={variant}
        color={color}
        style={{ cursor: isEllipsis ? 'pointer' : 'text', ...style }}
        ref={textRef}
      >
        {amountValue.slice(0, 9) + '...'} {currency && '$'}
      </Typography>
    </Tooltip>
  );
};

export const renderCellAmount = (args: TableCellAmountProps) => (
  <TableCellAmount {...args} />
);
