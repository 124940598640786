import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { CardTariff } from './CardTariff';
import { PROJECT_TYPE } from '@shared/libs';
import { PageNotFound } from '../StaticPage/PageNotFound';

export const TariffAnalyzer: FC<unknown> = () => {
  if (PROJECT_TYPE === 'hermespro') {
    return <PageNotFound />;
  }

  const { t } = useTranslation();

  const cardsData = [
    {
      title: t('titles.base'),
      hasLabel: false,
      mainAmountInYear: 'Free',
      hideText: true,
      subtitle: '',
      conditions: [`BTC, ETH, TRON explorer`, `${t('strings.transactionsGraph')}`],
      showSelect: false,
    },
    {
      title: t('titles.full'),
      hasLabel: false,
      mainAmountInYear: `${t('titles.from')} 20000`,
      subtitle: '',
      conditions: [
        `${t('strings.allBasePlan')}`,
        `${t('strings.riskScoring')}`,
        `${t('strings.markupAddresses')}`,
        `${t('strings.clusteringAddresses')}`,
      ],
    },
  ];

  return (
    <div className={styles['tariffs']}>
      <div className={styles['tariffs__group']}>
        {cardsData &&
          cardsData.map((item, i) => (
            <CardTariff
              key={i}
              title={item.title}
              hasLabel={item.hasLabel}
              mainAmountInYear={item.mainAmountInYear}
              hideText={item?.hideText}
              subtitle={item.subtitle}
              conditions={item.conditions}
              showSelect={item.showSelect}
            />
          ))}
      </div>
    </div>
  );
};
