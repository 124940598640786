import React, { FC, useCallback } from 'react';
import {
  Avatar,
  Divider,
  Dropdown,
  DropdownItem,
  Paper,
  Typography,
  AccountIcon,
  CurrencyDollarIcon,
  LogoutIcon,
} from '@rubin-dev/goblin';
import { history, router, RouterName } from '../../../../router';
import { useNavigate } from 'react-justanother-router';
import { logoutUser } from '@utils/auth';
import { useMeQuery } from '@apolloGenerated';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PROJECT_TYPE } from '@shared/libs';

export const ProfileControl: FC = () => {
  const { t } = useTranslation();
  const { loading, data } = useMeQuery();
  const user = data?.me;
  const { navigate } = useNavigate();

  const logoutCallback = useCallback(() => {
    logoutUser();
    history.navigate(router.urlFor(RouterName.AuthLogin));
  }, []);

  if (!user || loading)
    return (
      <Typography
        to={RouterName.AuthLogin}
        variant="body-16"
        className={styles.profileControl__signIn}
        color="neutrals-4"
      >
        {t('links.Signin')}
      </Typography>
    );
  return (
    <Dropdown
      targetSlot={<Avatar name={user.name} className={styles.profileControl__account} />}
      placement="right-end"
      width={260}
      zIndex={1000}
    >
      <Paper px={4} py={4} radius={4}>
        <DropdownItem onClick={() => navigate(RouterName.Profile)} fullWidth>
          <div className={styles.profileControl__group}>
            <AccountIcon />
            <Typography variant="body-16" color="on-surface-primary-1">
              {t('buttons.profile')}
            </Typography>
          </div>
        </DropdownItem>
        {PROJECT_TYPE !== 'hermespro' && (
          <DropdownItem onClick={() => navigate(RouterName.TariffAnalyzer)} fullWidth>
            <div className={styles.profileControl__group}>
              <CurrencyDollarIcon />
              <Typography variant="body-16" color="on-surface-primary-1">
                {t('titles.pricingPlans')}
              </Typography>
            </div>
          </DropdownItem>
        )}
        <Divider className={styles.profileControl__divider} />
        <DropdownItem onClick={logoutCallback} fullWidth>
          <div className={styles.profileControl__group}>
            <LogoutIcon color={'#B8433D'} />
            <Typography variant="body-16" color="error-2">
              {t('buttons.logout')}
            </Typography>
          </div>
        </DropdownItem>
      </Paper>
    </Dropdown>
  );
};
