import { JSONSchemaType } from 'ajv';
import { TableType } from '@graph/types';
import {
  AddressCheckedType,
  AddressTransactionsFilterTxType,
  Cluster,
  GraphHashes,
  GraphLink,
  GraphMeta,
  GraphNode,
  GraphSaveData,
  Network,
  NodeCoords,
  ZoomParamsType,
} from '@apolloGenerated';

export const graphNetworkScheme: JSONSchemaType<Network> = {
  type: 'string',
  enum: Object.values(Network),
};
export const txTypeScheme: JSONSchemaType<AddressTransactionsFilterTxType> = {
  type: 'string',
  enum: Object.values(AddressTransactionsFilterTxType),
};
export const graphTableTypeScheme: JSONSchemaType<TableType> = {
  type: 'string',
  enum: Object.values(TableType),
};
export const graphHashesScheme: JSONSchemaType<GraphHashes> = {
  type: 'object',
  properties: {
    hash: { type: 'string' },
    type: graphTableTypeScheme,
    __typename: { type: 'string', nullable: true },
  },
  required: ['hash', 'type'],
  additionalProperties: false,
};
export const graphCanvasScheme: JSONSchemaType<ZoomParamsType> = {
  type: 'object',
  properties: {
    originX: { type: 'number', nullable: true },
    originY: { type: 'number', nullable: true },
    zoom: { type: 'number', nullable: true },
    __typename: { type: 'string', nullable: true },
  },
  additionalProperties: false,
};
export const graphMetaScheme: JSONSchemaType<GraphMeta> = {
  type: 'object',
  properties: {
    name: { type: 'string' },
    canvas: { ...graphCanvasScheme, nullable: true },
    current: graphHashesScheme,
    network: graphNetworkScheme,
    __typename: { type: 'string', nullable: true },
  },
  required: ['current', 'name', 'network'],
  additionalProperties: false,
};
export const clusterScheme: JSONSchemaType<Cluster> = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    owner: { type: 'string', nullable: true },
    riskCategory: { type: 'number', nullable: true },
    riskVersion: { type: 'number', nullable: true },
    __typename: { type: 'string', nullable: true },
  },
  required: ['id'],
  additionalProperties: false,
};
export const graphNodeCoordsScheme: JSONSchemaType<NodeCoords> = {
  type: 'object',
  properties: {
    x: { type: 'number' },
    y: { type: 'number' },
    __typename: { type: 'string', nullable: true },
  },
  required: ['x', 'y'],
  additionalProperties: false,
};
export const graphNodeCheckedScheme: JSONSchemaType<AddressCheckedType> = {
  type: 'object',
  properties: {
    txid: { type: 'string' },
    source: { type: 'string' },
    target: { type: 'string' },
    type: txTypeScheme,
    __typename: { type: 'string', nullable: true },
  },
  required: ['type', 'txid', 'source', 'target'],
  additionalProperties: false,
};
export const graphNodeScheme: JSONSchemaType<GraphNode> = {
  type: 'object',
  properties: {
    hash: { type: 'string' },
    total: { type: 'number' },
    totalIn: { type: 'number' },
    totalOut: { type: 'number' },
    balance: { type: 'number' },
    hasMention: { type: 'boolean' },
    category: { type: 'string' },
    risk: { type: 'number', nullable: true },
    annotations: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          text: { type: 'string' },
          y: { type: 'number' },
          x: { type: 'number' },
          __typename: { type: 'string', nullable: true },
        },
        required: ['text', 'y', 'x'],
        additionalProperties: false,
      },
    },
    tableHash: { type: 'string' },
    disabled: { type: 'boolean', nullable: true },
    reportedCategory: { type: 'string', nullable: true },
    cluster: { ...clusterScheme, nullable: true },
    coords: { ...graphNodeCoordsScheme, nullable: true },
    checked: { type: 'array', items: graphNodeCheckedScheme },
    __typename: { type: 'string', nullable: true },
  },
  required: [
    'hash',
    'total',
    'totalIn',
    'totalOut',
    'balance',
    'hasMention',
    'category',
    'tableHash',
    'checked',
  ],
  additionalProperties: false,
};
export const graphLinkScheme: JSONSchemaType<GraphLink> = {
  type: 'object',
  properties: {
    __typename: { type: 'string', nullable: true },
    target: { type: 'string' },
    source: { type: 'string' },
    color: { type: 'string', nullable: true },
    annotation: { type: 'string', nullable: true },
    amount: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: txTypeScheme,
          value: { type: 'number' },
          txid: { type: 'string' },
          __typename: { type: 'string', nullable: true },
        },
        required: ['type', 'value', 'txid'],
        additionalProperties: false,
      },
    },
  },
  required: ['target', 'source', 'amount'],
  additionalProperties: false,
};
export const graphSaveScheme: JSONSchemaType<GraphSaveData> = {
  type: 'object',
  properties: {
    meta: graphMetaScheme,
    graph: {
      type: 'object',
      properties: {
        nodes: {
          type: 'array',
          items: graphNodeScheme,
        },
        links: {
          type: 'array',
          items: graphLinkScheme,
        },
        __typename: { type: 'string', nullable: true },
      },
      required: ['nodes', 'links'],
      additionalProperties: false,
    },
    __typename: { type: 'string', nullable: true },
  },
  required: ['meta', 'graph'],
  additionalProperties: false,
};
