import { EventName, useGraphStore } from '@graph/libs';
import { useEffect } from 'react';
import { useGraphAnnotation } from '@graph/libs/hooks';

export const useGraphNodeController = () => {
  const { onAddNodeAnnotation, onAddNodePin } = useGraphAnnotation();
  const { event } = useGraphStore();

  useEffect(() => {
    switch (event?.type) {
      case EventName.MENU_NODE_ADD_ANNOTATION: {
        onAddNodeAnnotation(event.params!.hash);
        break;
      }
      case EventName.MENU_NODE_ADD_PIN: {
        onAddNodePin(event.params!.hash, event.params!.disabled);
      }
    }
  }, [event]);
};
