import React, { FC, useState } from 'react';
import {
  GraphWindow,
  useSidebarData,
  useSidebarWindow,
} from '@graph-sidebar-module/shared';
import {
  ArrowBottomDenseIcon,
  ArrowTopDenseIcon,
  CheckIcon,
  CopyHash,
  Divider,
  DriverHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  Spacer,
  Toggle,
  ToggleItem,
  Typography,
} from '@rubin-dev/goblin';
import { openAddress } from '@shared/libs';
import { useTranslation } from 'react-i18next';
import {
  AddressInfo,
  AddressTransactionsTable,
} from '@graph-sidebar-module/entities/Address';

import cls from './styles.module.scss';
import { Network } from '@apolloGenerated';
import { NetworkIcon, NetworkIconEnum } from '@shared/ui';
import cx from 'classnames';
import { useCurrencyList } from '@hooks/useCurrencyList';

export enum TabAssetCurrency {
  Asset = 'asset',
  Usdt = 'usdt',
}

export const AddressWindow: FC = () => {
  const { t } = useTranslation();
  const { hash, network, isLoading } = useSidebarData();
  const [{ onHide }, { isShow }] = useSidebarWindow();

  const { currentCurrency, onChangeCurrentCurrency } = useCurrencyList();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [assetTab, setAssetTab] = useState<TabAssetCurrency>(TabAssetCurrency.Asset);

  const listButtons: ToggleItem<TabAssetCurrency>[] = [
    { value: TabAssetCurrency.Asset, label: t('titles.asset') },
    { value: TabAssetCurrency.Usdt, label: t('titles.usd') },
  ];

  const dropDownList: [Network | NetworkIconEnum, string][] = [
    [NetworkIconEnum.Usdt, t('network.usdt')],
    [NetworkIconEnum.Trx, t('network.trx')],
  ];

  const onChange = (val: Network | NetworkIconEnum) => {
    setIsOpen(false);
    onChangeCurrentCurrency(val);
  };

  const isActive = (network: Network | NetworkIconEnum): boolean =>
    currentCurrency === network;

  const onChangeToggle = (val: TabAssetCurrency) => {
    setAssetTab(val);
    // onChange(val === TabAssetCurrency.Asset ? currentCurrency : NetworkIconEnum.Usdt);
  };

  return (
    <GraphWindow
      isOpen={isShow}
      onClose={onHide}
      width={763}
      isLoading={isLoading}
      headerSlot={
        <div className={cls.container}>
          <DriverHeader
            titleSlot={
              <CopyHash
                hash={hash}
                variant="head-24"
                className={cls.copyHash}
                onClickHash={() => openAddress(network, hash)}
              />
            }
          />
          <div className={cls.wrapper}>
            <Dropdown
              value={isOpen}
              onChange={setIsOpen}
              targetSlot={
                <div className={cx(cls.group, cls.group_border)}>
                  <NetworkIcon width={16} height={16} network={currentCurrency} />
                  {currentCurrency}
                  <div className={cls.icon}>
                    {isOpen ? <ArrowTopDenseIcon /> : <ArrowBottomDenseIcon />}
                  </div>
                </div>
              }
              placement="bottom-start"
              width={200}
            >
              <DropdownList>
                {dropDownList &&
                  dropDownList.map(([key, value]) => (
                    <DropdownItem
                      key={key}
                      fullWidth
                      className={cls.item}
                      onClick={() => onChange(key)}
                    >
                      <div className={cls.group}>
                        <NetworkIcon network={key} width={24} height={24} />
                        {value}
                        {isActive(key) && <CheckIcon color={'#4067CA'} />}
                      </div>
                    </DropdownItem>
                  ))}
              </DropdownList>
            </Dropdown>
            <Toggle
              initValue={assetTab}
              items={listButtons}
              size="small"
              onChange={onChangeToggle}
            />
          </div>
        </div>
      }
    >
      <AddressInfo
        address={hash}
        network={network}
        currentCurrency={currentCurrency}
        isAsset={assetTab === TabAssetCurrency.Asset}
      />
      <Spacer size={20} />
      <Typography variant="head-20" color="on-surface-primary-1">
        {t('strings.transactions')}
      </Typography>
      <Spacer size={8} />
      <Divider />
      <Spacer size={12} />
      <AddressTransactionsTable
        currentCurrency={currentCurrency}
        isAsset={assetTab === TabAssetCurrency.Asset}
      />
    </GraphWindow>
  );
};
