import React, {
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExplorerAddressTransactions,
  ExplorerAddressTransactionTitle,
  ExplorerCategoriesTable,
  ExplorerAddressMentions,
  MentionsTitle,
} from '../../component';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';

import {
  useExplorerFindAddressByHashQuery,
  useExplorerRiskQuery,
  useMeQuery,
} from '@apolloGenerated';
import {
  Button,
  CopyHash,
  ExportIcon,
  Loader,
  LoaderOverlay,
  Spacer,
  TabItem,
  Tabs,
  VisualizationDenseIcon,
} from '@rubin-dev/goblin';
import { router, RouterName } from '../../router';
import styles from './explorer-address.module.scss';
import { AddressMainInfo } from '@component/Address';
import { openVisualizationAddress } from '@shared/libs';

type BtcAddressScreenProps = {
  params: {
    address: string;
    network: string;
  };
};

enum ExplorerAddressTabs {
  CATEGORY,
  TRANSACTIONS,
  MENTIONS,
}

export const ExplorerAddressScreen: FC<BtcAddressScreenProps> = ({
  params: { address, network },
}): JSX.Element => {
  const { t } = useTranslation();
  const { data: user, loading: userLoading } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  const [tab, setActiveTab] = useState<ExplorerAddressTabs>(ExplorerAddressTabs.CATEGORY);

  const { loading, data: addressData } = useExplorerFindAddressByHashQuery({
    variables: {
      network: getNetworkEnum(network),
      address: address,
    },
  });

  const node = useMemo(() => addressData?.explorerFindAddressByHash.node, [addressData]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (!expireDate && !userLoading) {
      setActiveTab(ExplorerAddressTabs.TRANSACTIONS);
    }
  }, [expireDate, userLoading]);

  const { loading: isLoadingRisk, data: riskData } = useExplorerRiskQuery({
    variables: {
      network: getNetworkEnum(network),
      address: address,
    },
  });
  const risk = useMemo(() => riskData?.explorerRisk?.risk, [riskData]);

  const pdfPage = useRef(null);

  const handleDownloadPdf = useCallback(() => {
    window.open(
      router.urlFor(RouterName.DownloadPDF, {
        address,
        network,
      }),
      '_blank',
      'noopener,noreferrer',
    );
  }, [network]);

  return (
    <div className={styles.explorerAddress} ref={pdfPage}>
      <div className={styles.explorerAddress__head}>
        <CopyHash hash={address} variant="head-20" shorted />
        <Button
          id={'visualization'}
          icon
          size="small"
          prependIcon={() => VisualizationDenseIcon({ color: '#394363' })}
          onClick={() => openVisualizationAddress(network, address)}
          variant="outlined"
        />
      </div>
      <Spacer size={8} />
      <AddressMainInfo address={address} network={getNetworkEnum(network)} />
      <Spacer size={20} />
      <div className={styles.explorerAddress__tabs}>
        <Tabs key={tab} initValue={tab} onChange={setActiveTab}>
          <TabItem value={ExplorerAddressTabs.CATEGORY} disabled={!expireDate}>
            {t('links.category')}{' '}
            {isLoadingRisk ? (
              <Loader size={16} />
            ) : (
              expireDate && `(${risk?.calculated?.items?.length || 0})`
            )}
          </TabItem>
          <TabItem value={ExplorerAddressTabs.TRANSACTIONS}>
            <ExplorerAddressTransactionTitle
              network={getNetworkEnum(network)}
              address={address}
            />
          </TabItem>
          <TabItem value={ExplorerAddressTabs.MENTIONS} disabled={!expireDate}>
            <MentionsTitle network={network} address={address} />
          </TabItem>
        </Tabs>
        {expireDate && user?.me && (
          <Button
            onClick={handleDownloadPdf}
            prependIcon={ExportIcon}
            icon
            size="large"
            variant="text"
          />
        )}
      </div>
      <Spacer size={16} />
      {tab === ExplorerAddressTabs.CATEGORY && (
        <>
          {isLoadingRisk ? (
            <Loader />
          ) : (
            <ExplorerCategoriesTable
              address={address}
              network={network}
              node={node}
              risk={risk?.risk}
              items={risk?.calculated?.items}
            />
          )}
        </>
      )}
      {tab === ExplorerAddressTabs.TRANSACTIONS && (
        <ExplorerAddressTransactions network={network} address={address} />
      )}
      {tab === ExplorerAddressTabs.MENTIONS && (
        <ExplorerAddressMentions network={network} address={address} />
      )}
      <LoaderOverlay show={loading} />
    </div>
  );
};
