import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { Network, useExplorerMentionRemoveMutation } from '@apolloGenerated';
import { history, router, RouterName } from '../../router';

export type DialogMarkingDeleteProps = {
  uuid: string;
  network: Network;

  onSuccess?(): void;
};
declare global {
  interface ModalProps {
    dialogMarkingDelete: DialogMarkingDeleteProps;
  }
}
export const DialogMarkingDelete: FC<WithCloseModal<DialogMarkingDeleteProps>> = ({
  uuid,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation();
  const [mutate, { error, loading }] = useExplorerMentionRemoveMutation();

  const handleSubmit = async () => {
    await mutate({
      variables: {
        req: {
          uuid,
        },
      },
    });
    onSuccess && onSuccess();
    onClose();

    if (!error) {
      history.navigate(router.urlFor(RouterName.Marking));
    }
  };

  return (
    <Dialog
      width={500}
      title={t('titles.confirmActions')}
      footerSlot={
        <DialogFooter
          position="end"
          startSlot={
            <Button size="large" color="secondary" fullWidth onClick={onClose}>
              {t('titles.cancel')}
            </Button>
          }
          endSlot={
            <Button onClick={handleSubmit} size="large" disabled={loading} fullWidth>
              {t('buttons.delete')}
            </Button>
          }
        />
      }
    >
      <Typography className="break-word" variant="body-16" color="on-surface-primary-1">
        {t('titles.reportDeleted')}
      </Typography>
    </Dialog>
  );
};
