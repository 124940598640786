import React, { FC, useMemo } from 'react';
import { getNetworkEnum } from '@helpers/address';
import {
  AddressTransactionsFilterTxType,
  Network,
  useExplorerFindAddressByHashQuery,
} from '@apolloGenerated';
import { BalanceCard } from '@graph-sidebar-module/shared';
import { Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import cls from './style.module.scss';
import { NetworkIconEnum, TableCellAmount } from '@shared/ui';
import { useCurrencyList } from '@hooks/useCurrencyList';
import { BigNumber } from 'bignumber.js';

type AddressTransactionsCardProps = {
  address: string;
  network: string;
  isSimpleView?: boolean;
  currentCurrency?: Network | NetworkIconEnum;
  isAsset?: boolean;
};
export const AddressBalanceCard: FC<AddressTransactionsCardProps> = ({
  address,
  network,
  isAsset,
  isSimpleView,
  currentCurrency,
}) => {
  const { t } = useTranslation();

  const { currencies } = useCurrencyList();

  const { data, loading } = useExplorerFindAddressByHashQuery({
    variables: { network: getNetworkEnum(network), address },
  });

  const node = useMemo(() => data?.explorerFindAddressByHash?.node, [data]);

  const convertTotal = (total: string | undefined): string => {
    if (!total || !currencies[network.toLowerCase()]) return '0';

    const amountUsdt = String(
      BigNumber(total).multipliedBy(currencies[network.toLowerCase()]),
    );

    let amount = amountUsdt;

    if (currentCurrency !== NetworkIconEnum.Usdt && currentCurrency && isAsset) {
      amount = String(
        BigNumber(amountUsdt).dividedBy(currencies[currentCurrency.toLowerCase()]),
      );
    }

    return String(amount);
  };

  const total = useMemo(() => {
    return convertTotal(node?.total);
  }, [node, currencies, currentCurrency, isAsset]);

  const totalIn = useMemo(() => {
    return convertTotal(node?.totalIn);
  }, [node, currencies, currentCurrency, isAsset]);

  const totalOut = useMemo(() => {
    return convertTotal(node?.totalOut);
  }, [node, currencies, currentCurrency, isAsset]);

  if (isSimpleView) {
    return (
      <div className={cls.wrapper}>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('strings.balance')}
          </Typography>
          <Typography
            tag={'div'}
            variant="body-14"
            color="on-surface-primary-1"
            className={cls.value}
          >
            <TableCellAmount
              style={{ maxWidth: '80px' }}
              amount={total}
              network={network}
              showSymbol={false}
              variant="body-14"
              type={AddressTransactionsFilterTxType.All}
            />
            {isAsset ? currentCurrency && getNetworkEnum(currentCurrency) : '$'}
          </Typography>
        </div>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('strings.received')}
          </Typography>
          <Typography
            tag={'div'}
            variant="body-14"
            color="success-2"
            className={cls.value}
          >
            <TableCellAmount
              style={{ maxWidth: '80px' }}
              amount={totalIn}
              network={network}
              showSymbol={false}
              variant="body-14"
              type={AddressTransactionsFilterTxType.Receives}
            />
            {isAsset ? currentCurrency && getNetworkEnum(currentCurrency) : '$'}
          </Typography>
        </div>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('strings.sent')}
          </Typography>
          <Typography tag={'div'} variant="body-14" color="error-2" className={cls.value}>
            <TableCellAmount
              style={{ maxWidth: '80px' }}
              amount={totalOut}
              network={network}
              showSymbol={false}
              variant="body-14"
              type={AddressTransactionsFilterTxType.Sent}
            />
            {isAsset ? currentCurrency && getNetworkEnum(currentCurrency) : '$'}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <BalanceCard
      network={network}
      loading={loading}
      total={Number(node?.total || 0)}
      totalIn={Number(node?.totalIn || 0)}
      totalOut={Number(node?.totalOut || 0)}
    />
  );
};
