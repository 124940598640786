import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import {
  Button,
  TextField,
  LoaderOverlay,
  useForm,
  FormGenericData,
  Spacer,
} from '@rubin-dev/goblin';
import { PROJECT_TYPE } from '@shared/libs';

export type AuthLoginFormContainerProps = FormGenericData<AuthLoginFormInput>;

export type AuthLoginFormInput = {
  email: string;
  password: string;
};

const defaultValues: AuthLoginFormInput = {
  email: '',
  password: '',
};

export const AuthLoginFormContainer: FC<AuthLoginFormContainerProps> = ({
  loading,
  formOnSubmit,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation();
  const { errors, onChange, onCmdEnterSubmit, onSubmit, values } =
    useForm<AuthLoginFormInput>(formOnSubmit, {
      ...defaultValues,
      ...initialValues,
    });

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <TextField
        id="email"
        name="email"
        size="large"
        autoComplete="username"
        placeholder={t('titles.email')}
        value={values.email.toLowerCase()}
        onChange={onChange}
        error={errors?.email}
        fullWidth
        autoFocus
      />
      <Spacer size={12} />
      <TextField
        id="password"
        name="password"
        type="password"
        size="large"
        autoComplete="current-password"
        placeholder={t('titles.password')}
        initValue={values.password}
        onChange={onChange}
        onBlur={console.log}
        fullWidth
        error={errors?.password}
      />
      <Spacer size={12} />
      <Button type="submit">{t('buttons.login')}</Button>
      <Spacer size={8} horizontal />
      {PROJECT_TYPE !== 'hermespro' && (
        <>
          <Button to={RouterName.AuthRegistration} variant="outlined">
            {t('buttons.register')}
          </Button>
          <Spacer size={8} horizontal />
          <Button to={RouterName.AuthRestore} variant="outlined">
            {t('links.restorepassword')}
          </Button>
        </>
      )}
      <LoaderOverlay show={loading} />
    </form>
  );
};
