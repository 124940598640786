import React, { FC } from 'react';
import { Button, Dialog, Typography, WithCloseModal } from '@rubin-dev/goblin';
import styles from './styles/remove-dialog.module.scss';
import { useTranslation } from 'react-i18next';
import { useDeleteMutation } from '@apolloGenerated';

export type Props = {
  userId: number;
  updateUserList: () => void;
};

declare global {
  interface ModalProps {
    removeUserDialog: Props;
  }
}

export const RemoveUserDialog: FC<WithCloseModal<Props>> = ({
  userId,
  onClose,
  updateUserList,
}) => {
  const { t } = useTranslation();

  const [deleteMutation] = useDeleteMutation({});

  const onConfirm = async () => {
    try {
      await deleteMutation({
        variables: {
          input: {
            userId,
          },
        },
      });
    } finally {
      onClose();
      updateUserList();
    }
  };

  return (
    <Dialog
      width={500}
      title={
        <Typography tag="h3" variant="head-24" color="on-surface-primary-1">
          {t('titles.confirmDelete')}
        </Typography>
      }
      closed
    >
      <div>
        <Typography variant={'body-16'} color={'on-surface-primary-1'}>
          {t('titles.userDelete')}
        </Typography>
        <div className={styles.removeDialog__action}>
          <Button color={'secondary'} size={'large'} onClick={onClose}>
            {t('titles.cancel')}
          </Button>
          <Button size={'large'} onClick={onConfirm}>
            {t('buttons.delete')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
