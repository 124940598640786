import { NodeItem } from '@graph/types';
import { EventName, useGraphStore } from '@graph/libs';
import { useCallback } from 'react';

export type UseMenuNodeEvent = {
  onRemove(): void;
  onAddAnnotation(): void;
  onAddPin(): void;
};
export type NodeEvents = 'remove' | 'add-annotation' | 'add-pin';
export const useMenuNodeEvent = (
  item: NodeItem,
  callback?: () => void,
): UseMenuNodeEvent => {
  const { sendEvent } = useGraphStore();

  const handleRemove = useCallback(() => {
    sendEvent({ type: EventName.GRAPH_NODE_REMOVE, params: { hash: item.uuid } });
  }, [item, sendEvent]);

  const handleAddAnnotation = useCallback(() => {
    sendEvent({ type: EventName.MENU_NODE_ADD_ANNOTATION, params: { hash: item.uuid } });
  }, [item, sendEvent]);

  const handleAddPin = useCallback(() => {
    sendEvent({
      type: EventName.MENU_NODE_ADD_PIN,
      params: { hash: item.uuid, disabled: !item.disabled },
    });
  }, [item, sendEvent]);

  const handleEvent = useCallback(
    (event: NodeEvents) => () => {
      switch (event) {
        case 'remove':
          handleRemove();
          break;
        case 'add-annotation':
          handleAddAnnotation();
          break;
        case 'add-pin':
          handleAddPin();
          break;
      }
      callback && callback();
    },
    [callback, handleRemove],
  );

  return {
    onRemove: handleEvent('remove'),
    onAddAnnotation: handleEvent('add-annotation'),
    onAddPin: handleEvent('add-pin'),
  };
};
