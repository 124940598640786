import React, { FC, useEffect, useState } from 'react';
import { Button, ArrowLeftIcon } from '@rubin-dev/goblin';
import styles from './contentMarking.module.scss';
import { FormMarking, MarkingForm } from '@component/Marking/FormMarking';
import {
  Network,
  OtherAddress,
  useExplorerMarkupCreateMutation,
  useExplorerScreenshotUploadMutation,
} from '@apolloGenerated';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import { detectNetworkByAddress } from '@aml/validation';
import { useNavigate } from 'react-justanother-router';

interface Props {
  id: number;
  address: string;
  network: Network;
}

export const MarkingEditComponent: FC<Props> = ({ id, address, network }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const [currentAddress, setCurrentAddress] = useState<string | null>(null);

  const [mutate] = useExplorerMarkupCreateMutation();
  const [mutateUpload] = useExplorerScreenshotUploadMutation();

  const onSubmit = async (values: MarkingForm) => {
    setCurrentAddress(values?.address || '');
    const detectionNetwork = values?.address
      ? detectNetworkByAddress(values?.address)?.toUpperCase()
      : '';

    if (!values?.uuid) return;

    const otherAddresses = values?.otherAddresses.length
      ? (values?.otherAddresses.map((item) => ({
          network: detectNetworkByAddress(item)?.toUpperCase(),
          address: String(item || ''),
        })) as OtherAddress[])
      : [];

    const currentValues = {
      ...values,
    };

    if (currentValues.screenshots && currentValues.screenshots.length) {
      await mutateUpload({
        variables: {
          req: {
            uuid: currentValues?.uuid,
            screenshots: currentValues.screenshots,
          },
        },
      });
    }

    delete currentValues.screenshots;
    await mutate({
      variables: {
        // @ts-ignore
        req: {
          ...currentValues,
          ...(!detectionNetwork ? {} : { network: detectionNetwork?.toUpperCase() }),
          otherAddresses,
          uuid: currentValues?.uuid,
          level: 160,
          mentionSourceID: currentValues.mentionSourceID,
        },
      },
    });
  };

  useEffect(() => {
    if (currentAddress) {
      navigate(RouterName.Marking, {}, { query: currentAddress });
    }
  }, [currentAddress]);

  const onPrevPage = () => {
    navigate(RouterName.Marking, {}, { query: address });
  };

  return (
    <section className={styles.section}>
      <Button
        variant={'outlined'}
        icon
        prependIcon={ArrowLeftIcon}
        onClick={onPrevPage}
      />
      <FormMarking
        id={id}
        isEdit
        network={network}
        title={t('marking.edit')}
        address={address}
        formOnSubmit={onSubmit}
      />
    </section>
  );
};
