import React, { FC, useLayoutEffect, useMemo, useRef } from 'react';
import { Header } from './Header';
import { getNetworkEnum } from '@helpers/address';
import {
  useExplorerAddressActivityQuery,
  useExplorerAddressTransactionStatsQuery,
  useExplorerClusterForAddressQuery,
  useExplorerFindAddressByHashQuery,
  useExplorerRiskQuery,
} from '@apolloGenerated';
import { amount } from '@aml/amount';
import { BigNumber } from 'bignumber.js';
import { DescriptionCategories } from '@component/downloadPDF/ui/DescriptionCategories';

import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Text,
} from '@react-pdf/renderer';
import { Table } from '@component/downloadPDF/ui/Table';
import sdk from '@aml/sdk';
import { ExplorerCategoriesTable } from '@component/explorer';
import html2canvas from 'html2canvas';
import { timestampToHumanDate } from '@utils/timestamp';

interface Props {
  address: string;
  network: string;
}

const styles = StyleSheet.create({
  body: {
    padding: 12,
  },
  title: {
    fontSize: 14,
  },
  section: {
    flexGrow: 1,
  },
  wrapper: {
    marginBottom: 20,
    flexDirection: 'column',
  },
  time: {
    fontSize: 14,
    color: '#798597',
  },
  image: {
    height: 250,
    objectFit: 'cover',
  },
});

export const Wrapper: FC<Props> = ({ address, network }) => {
  const pieRef = useRef(null);

  const variables = {
    address,
    network: getNetworkEnum(network),
  };

  // TRANSACTIONS
  const { data: dataTransactions } = useExplorerAddressTransactionStatsQuery({
    variables,
  });
  const stats = dataTransactions?.explorerAddressTransactionStats?.stats;
  const total = stats?.total || 0;
  const totalSent = stats?.sent || 0;
  const totalReceived = stats?.received || 0;

  // CLUSTER
  const { data: clusterData } = useExplorerClusterForAddressQuery({
    variables,
  });
  const cluster = clusterData?.explorerClusterForAddress?.cluster;

  // BALANCE
  const { data: dataBalance } = useExplorerFindAddressByHashQuery({
    variables,
  });
  const node = dataBalance?.explorerFindAddressByHash?.node;
  const formatBalance = (val?: string) =>
    Number(val) > 0 ? amount(BigNumber(val || 0).toString(10), network, false) : 0;
  const balance = formatBalance(node?.total);
  const balanceIn = formatBalance(node?.totalIn);
  const balanceOut = formatBalance(node?.totalOut);

  const { data: categoryDict, refetch } =
    sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  useLayoutEffect(() => {
    refetch();
  });

  // RISK
  const { data: riskData } = useExplorerRiskQuery({
    variables,
  });

  const risk = Number(riskData?.explorerRisk?.risk?.risk || 0);

  const items = useMemo(() => riskData?.explorerRisk?.risk, [riskData]);

  const tableItems = useMemo(() => {
    return riskData?.explorerRisk.risk?.calculated?.items.map((item) => {
      const findCategory =
        categoryDict?.params &&
        Object.values(categoryDict?.params).find(
          (category) => category.number === item.number,
        );

      return {
        ...item,
        colorRisk: findCategory?.risk,
      };
    });
  }, [riskData, categoryDict]);

  const categoryName = useMemo(() => {
    const findCategory = riskData?.explorerAddressCategories?.categories?.find(
      (category) => category.number === riskData.explorerRisk.risk?.reported?.number,
    );
    return findCategory ? findCategory.name : '';
  }, [riskData]);

  // ACTIVITY
  const { data } = useExplorerAddressActivityQuery({
    variables,
  });
  const activity = data?.explorerAddressActivity?.activity;

  const { data: riskCurrentDist } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const image = async () => {
    if (!pieRef.current) return '';

    const canvas = await html2canvas(pieRef.current);

    return canvas.toDataURL('image/png');
  };

  const CreateDocument = () => (
    <Document>
      <Page size={'A4'} style={styles.body}>
        <View style={styles.wrapper}>
          <Text style={styles.title}>AML Risk Report</Text>
          <Text style={styles.time}>{`Generation At: ${timestampToHumanDate(
            new Date().getTime(),
          )}`}</Text>
        </View>
        <View style={styles.section}>
          <Header
            address={address}
            network={network}
            risk={risk}
            categoryName={categoryName}
            balance={{
              balance,
              balanceIn,
              balanceOut,
            }}
            transaction={{
              total,
              totalSent,
              totalReceived,
            }}
            cluster={cluster}
            activity={{ last: activity?.last, first: activity?.first }}
          />
        </View>
        <View>
          <Image src={image} style={styles.image} />
        </View>
        {riskCurrentDist && items?.calculated?.items && (
          <>
            <Table data={riskCurrentDist} items={tableItems} />
            <DescriptionCategories data={riskCurrentDist} items={tableItems} />
          </>
        )}
      </Page>
    </Document>
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <PDFViewer width={'100%'} style={{ height: '100vh' }}>
        <CreateDocument />
      </PDFViewer>
      <div style={{ opacity: 0, height: 0, width: 1300 }}>
        <ExplorerCategoriesTable
          ref={pieRef}
          network={network}
          address={address}
          node={node}
          items={items?.calculated?.items}
          hasOnlyCharts
        />
      </div>
    </div>
  );
};
