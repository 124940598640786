import React, { FC, useEffect, useRef } from 'react';
import PieChartComponent, {
  CommonSeriesSettings,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
  LoadingIndicator,
} from 'devextreme-react/pie-chart';
import styles from './styles.module.scss';

type Props = {
  title?: string;
  subtitle?: string;
  network: string;
  data: { id: number; Import: number; Category: string }[];
  selectedCategory?: string;
  getColorsCallback?: (colors: { [key: string]: string }[]) => void;
  loading?: boolean;
};

export const PieChart: FC<Props> = ({
  title,
  subtitle,
  data,
  selectedCategory,
  getColorsCallback,
}) => {
  const pieChartRef = useRef<any>(null);
  const htmlTooltip = (arg: any) => {
    const percent =
      arg.seriesName === 'Import' ? arg.point.data.Import : arg.point.data.Export;

    const convertNumb = Math.floor(percent * 100) / 100;

    return `<div class='b-tooltip'>
      <span class="b-tooltip__tilte">${arg.argumentText}</span>
      <div class='b-tooltip__group'>
        <span class='b-tooltip__text b-tooltip__text--secondary'>Percentage:</span>
        <span class='b-tooltip__text'>${convertNumb > 0 ? convertNumb : '<0.01'}%</span>
      </div>
    </div>`;
  };
  const customizeTooltip = (arg: any) => {
    if (arg.argument === 'Empty') {
      return {
        text: '',
      };
    }

    return {
      html: htmlTooltip(arg),
    };
  };

  const customizeLabel = (e: any) => {
    return `${e.argumentText}`;
  };

  const customizePoint = (arg: any) => {
    return {
      color: arg.data.color,
    };
  };

  const onPointHoverChanged = (e: any) => {
    const point = e.target as any;
    if (point.isHovered() && point.argument !== 'Empty' && point.data.Export) {
      point.showTooltip();
    } else {
      point.hideTooltip();
    }
  };

  const pieChart = () => {
    return pieChartRef.current.instance;
  };

  useEffect(() => {
    const point = pieChart().getAllSeries()[0].getPointsByArg(selectedCategory)[0];
    if (point) {
      point.showTooltip(point);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const filterSeries = pieChartRef.current.instance.series
      .map((item: { pointsByArgument: {} }) => item.pointsByArgument)
      .filter((item: {}) => Object.keys(item).length > 0);

    if (!filterSeries.length) return;
    const filterObjectKeys = filterSeries.filter((item: any) => {
      return Object.keys(item).length;
    });

    const colorList = filterObjectKeys.reduce((acc: any, item: any) => {
      for (const accKey in item) {
        if (!acc[accKey]) {
          acc[accKey] = item[accKey][0]?.getColor();
        }
      }
      return acc;
    }, {});

    if (getColorsCallback) {
      getColorsCallback(colorList);
    }
  }, [data]);

  return (
    <div className={styles['pie']}>
      <PieChartComponent
        id={'pie'}
        type={'pie'}
        className={styles['pie__chart']}
        ref={pieChartRef}
        animation={false}
        innerRadius={10}
        height={450}
        diameter={0.5}
        width={'100%'}
        palette={'Soft Pastel'}
        sizeGroup="piesGroup"
        dataSource={data}
        customizePoint={customizePoint}
        onPointHoverChanged={onPointHoverChanged}
      >
        <Legend visible={false} />
        <LoadingIndicator enabled />
        <Title
          text={title}
          font={{
            size: '14px',
            weight: 400,
            color: '#7d7d7d',
          }}
          margin={{
            bottom: 72,
          }}
        >
          <Subtitle
            text={subtitle}
            offset={-8}
            font={{
              color: '#000',
              size: '16px',
              weight: 700,
            }}
          />
        </Title>

        <CommonSeriesSettings border={{ color: '#E4E8EE', visible: true, width: 2 }}>
          <Label
            visible={true}
            customizeText={customizeLabel}
            backgroundColor="none"
            radialOffset={20}
            wordWrap={'none'}
            textOverflow={'none'}
            font={{
              color: '#7d7d7d',
            }}
            connector={{
              color: '#7d7d7d',
              visible: true,
            }}
          />
        </CommonSeriesSettings>
        <Series
          hoverMode="none"
          name="Export"
          argumentField="Category"
          valueField="Export"
        />
        <Tooltip customizeTooltip={customizeTooltip} opacity={0} />
      </PieChartComponent>
    </div>
  );
};
